/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.BlogRelatedProducts {
    &-Wrapper {
        margin: 10px 0;

        @include desktop {
            margin: 13px 0 50px;
        }

        @include desktop {
            background-color: var(--secondary-base-color);
            border-radius: 16px;
            padding: 20px 20px 20px 10px;
        }

        button {
            @include desktop {
                pointer-events: none;
            }
        }

        .ProductLinks {
            @include mobile {
                display: none;
            }

            .ContentWrapper {
                background-color: var(--secondary-base-color);
                padding: 0;

                @include mobile {
                    border-radius: 16px;
                }
            }

            &-Title {
                display: none;
            }

            &-List {
                grid-template-columns: 100%;

                @include mobile {
                    padding-top: 30px;
                }

                .ProductCard {
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        @include desktop {
                            box-shadow: none;
                        }
                    }

                    &-LabelWrapper,
                    &-Reviews,
                    &-ProductActions {
                        display: none;
                    }

                    &-Link {
                        flex-direction: unset;
                        padding: 0 0 10px;

                        @include mobile {
                            padding-right: 20px;
                        }
                    }

                    &-Content {
                        align-items: unset;
                        display: grid;
                        width: 70%;
                        grid-template-columns: 60% 40%;
                        padding-top: 0;
                        background: transparent;

                        @media (max-width: 1100px) {
                            display: flex;
                        }

                        @include mobile {
                            display: grid;
                        }
                    }

                    &-Name {
                        grid-column: 1;

                        @media (min-width: 1101px) {
                            text-align: left;
                            display: flex;
                            align-items: flex-end;
                        }

                        @include mobile {
                            text-align: left;
                            display: flex;
                            align-items: flex-end;
                        }
                    }

                    &-FigureReview {
                        width: 30%;
                        background: transparent;

                        @media (max-width: 1100px) {
                            width: 40%
                        }

                        @include mobile {
                            width: 30%;
                        }

                        .ProductCard-Figure {
                            .Image {
                                mix-blend-mode: multiply;
                            }
                        }
                    }

                    &-Brand {
                        grid-column: 1;
                        margin-top: 0;

                        @media (min-width: 1101px) {
                            text-align: left;
                        }
                        @include mobile {
                            text-align: left;
                        }

                        & .ProductAttributeValue {
                            background-color: transparent;
                            margin: 0;
                            padding: 0;
                        }
                    }

                    &-PriceWrapper {
                        grid-column: 2;
                        grid-row: 1 / span 2;
                        margin-top: 0;
                        justify-content: center;

                        .ProductPrice  {
                            flex-direction: column;
                        }
                    }

                    &-Picture {
                        @include mobile {
                            padding-bottom: 50%;
                        }
                    }
                }
            }
        }

        &_isExpanded {
            .ProductLinks {
                display: initial;
            }
        }

        &_placeholder {
            @include desktop {
                background-image: var(--placeholder-image);
                background-size: var(--placeholder-size);
                animation: var(--placeholder-animation);
                border-radius: 15;
                height: 400px;
            }

            button,
            .ProductLinks {
                opacity: 0;
            }
        }

        .SliderWrapper {
            .ProductSlider {
                @include desktop {
                    flex-direction: column;
                    margin: 0;
                    gap: 15px;
                }

                .ProductCard {
                    @include desktop {
                        width: 85%;

                        &:first-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &-Title {
        text-align: left;

        @include mobile {
            padding-left: 20px;
        }

        @include desktop {
            margin-left: 10px;
            margin-top: 0;
        }
    }

    &-Button {
        @include mobile {
            background-color: var(--secondary-base-color);
            border-radius: 16px;
            font-size: 16px;
            width: 100%;
            margin-bottom: 10px;
        }

        &:after {
            @include mobile {
                border-color: var(--color-black);
                border-width: 0 3px 3px 0;
                content: '';
                height: 10px;
                right: 26px;
                transform: rotate(-45deg);
                width: 9px;
                display: block;
                pointer-events: none;
                position: absolute;
                bottom: 0;
                top: 4px;
                margin: auto;
                border-style: solid;
            }
        }

        &_isExpanded {
            @include mobile {
                background-color: var(--color-blue);
                color: var(--color-white);
            }

            &:after {
                border-color: var(--color-white);
                transform: translateY(-25%) rotate(45deg);
            }
        }
    }
}
