/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.SliderWrapper {
    .ProductSlider {
        display: flex;
        overflow: hidden;
        margin-inline: 32px;

        .ProductCard {
            width: 100%;
            flex-shrink: 0;

            &:first-child {
                @include desktop {
                    margin-inline-start: 32px;
                }
            }

            @include desktop {
                width: calc(25% - 40px);
                margin-inline-end: 32px;
            }

            .Image {
                &-Image {
                    object-fit: cover;
                }
            }

            &-AdditionalInfo,
            &-ProductCompareButton,
            &-GridButton,
            .ProductPrice-LeasingPrice {
                display: none;
            }
        }
    }

    .Slider {
        &-Arrow {
            inset-block-start: calc((100% - 131px) / 2);
            background-color: unset;

            &_isPrev {
                inset-inline-start: 0;
            }

            &_isNext {
                inset-inline-end: 0;
            }

            svg {
                fill: var(--body-content-secondary-color);
            }
        }
    }
}

.Slider.ProductSlider {
    &Widget {
        .Slider {
            &-Wrapper {
                margin: 0 16px;

                .ProductCard {
                    @include mobile {
                        padding-inline: 4px;
                    }

                    .Image {
                        &-Image {
                            object-fit: cover;
                        }
                    }

                    &-AdditionalInfo,
                    &-ProductCompareButton,
                    &-GridButton,
                    .ProductPrice-LeasingPrice {
                        display: none;
                    }
                }
            }
        }
    }
}
