/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductInformation {
    margin-block-end: 80px;

    @include desktop {
        margin-block-end: 208px;
    }

    &-Wrapper {
        border: 1px solid var(--background-stroke-color);
        padding: 16px;

        @include desktop {
            padding: 32px;
        }
    }

    &-Title {
        padding: 0 0 32px 0;

        @include mobile {
            font-size: 24px;
            line-height: 32px;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
    }
}
