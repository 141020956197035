/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductActions {
    &-Price {
        justify-content: flex-start;
    }

    &-PriceWrapper {
        margin-block-start: 0;
    }

    & .ProductPrice {
        &-PriceValue {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;

            @include desktop {
                font-size: 18px;
                margin-inline-end: 0;
            }
        }

        del {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            opacity: 1;
        }

        &-HighPrice {
            margin-inline-start: 12px;
            margin-inline-end: 0;
        }
    }

    &-Title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-block: 0 16px;

        @include desktop {
            font-size: 32px;
            line-height: 36px;
        }
    }

    &-Wrapper {
        background-color: var(--background-hover-color);
        padding: 16px;

        @include desktop {
            padding: 32px;
        }

        &Title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-block: 0 24px;

            @include desktop {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    &-PriceIncTax {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 24px;
        width: 100%;

        @include desktop {
            margin-block-end: 48px;
        }

        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;

            @include desktop {
                font-size: 30px;
                line-height: 34px;
            }
        }

        .ProductActions-Price {
            margin-block-start: 0;
        }

        .ProductPrice-PriceValue {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;

            @include desktop {
                font-size: 30px;
                line-height: 34px;
            }
        }
    }

    &-PriceExclTax,
    &-OrderTotal,
    &-TransportationPrice,
    &-RegistrationPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 16px;
        width: 100%;
        height: 24px;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            @include desktop {
                font-size: 20px;
            }
        }

        .ProductActions-Price {
            margin-block-start: 0;
        }

        .ProductPrice-PriceValue {
            font-weight: 500;
            font-size: 16px;
            line-height: 34px;

            @include desktop {
                font-size: 20px;
            }
        }
    }

    &-OrderTotal {
        border-top: 1px solid #BBB;
        padding-block-start: 32px;
        margin-block-start: 50px;
        margin-block-end: 32px;
        height: unset;

        @include desktop {
            margin-block-start: 100px;
        }
    }

    &-RegistrationNote {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 16px;
        width: 100%;

        span {
            font-size: 14px;
            line-height: 24px;

            @include desktop {
                font-size: 16px;
            }
        }

        .ProductActions-Price {
            margin-block-start: 0;
        }
    }

    &-RegistrationPrice {
        margin-block-end: 0;

        .with-tooltip {
            display: flex;
            justify-content: center;
            position: relative;

            .tooltip {
                position: absolute;
                width: 15px;
                height: 15px;
                background-image: url('../../style/icons/ProductPage/icon_info.svg');
                background-size: contain;
                background-repeat: no-repeat;
                inset-block-start: -10px;
                inset-inline-end: -15px;

                &:hover {
                    cursor: pointer;

                    .message {
                        display: block;
                    }
                }

                .message {
                    position: absolute;
                    width: 200px;
                    background: var(--background-white-color);
                    padding: 5px 10px;
                    border-radius: 10px;
                    display: none;
                    z-index: 1;

                    @include mobile {
                        inset-block-start: -45px;
                        inset-inline-start: 0;
                        transform: translate(-50%, -50%);
                    }

                    @include desktop {
                        inset-block-start: 50%;
                        inset-inline-start: calc(100% + 10px);
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &-AddToCartWrapper {
        width: 100%;
        justify-content: space-between;

        @include desktop {
            justify-content: flex-end;
        }

        .AddToCart {
            margin-inline-end: 0;
            text-transform: capitalize;
            line-height: 20px;
            font-weight: 500;
            font-size: 17px;
            width: 143px;
            order: 2;
        }

        button {
            @include mobile {
                min-width: auto;
            }
        }

        .ProductCompareButton {
            width: max-content;
            margin-inline-end: 24px;

            @include mobile {
                width: 150px;
                margin-inline-end: 10px;
                margin-inline-start: 0px;
            }

            span {
                line-height: 24px;
                font-size: 18px;
                font-weight: 400;
                display: block;
                margin-inline: 5px;

                @include mobile {
                    margin-inline-start: 5px;
                    margin-inline-end: 0px;
                    text-align: start;
                }
            }

            &_isActive {
                span {
                    color: var(--primary-base-color);
                }
            }

            &:hover {
                box-shadow: none;

                span {
                    color: var(--body-content-color);
                }

                svg {
                    fill: var(--body-content-color);
                }
            }
        }
    }
}
