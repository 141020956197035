/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductAdditionalInfo {
    margin-block-start: 32px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--background-stroke-color);

    @include desktop {
        padding-inline: 32px;
        padding-block-start: 56px;
        padding-block-end: 32px;
        margin-block-start: 0;
        border-inline: 1px solid var(--background-stroke-color);
        border-block-start: none;
        border-block-end: 1px solid var(--background-stroke-color);
    }

    &-Details {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 32px;

        span, p {
            margin-block: 0;
            width: 50%;
            font-size: 16px;

            @include desktop {
                font-size: 18px;
            }
        }

        span {
            font-weight: 700;
        }

        p {
            display: flex;
            align-items: center;
            gap: 8px;

            @include desktop {
                width: 177px;
            }

            a {
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        &_isHidden {
            @media print {
                display: none;
            }
        }
    }

    .Link {
        &.lv_ru, &.lt_ru, &.ee_ru {
            font-weight: 700;
        }
    }
}
