/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductGallery {
    margin-block-end: 0;
    margin-inline: 0;
    height: auto;

    &-Additional {
        border-inline: 1px solid var(--background-stroke-color);
        border-block-end: none;
        padding-block-end: 0;

        &_isImageZoomPopupActive {
            .CarouselScrollItem {
                filter: opacity(1);
            }
        }

        &_isWithEmptySwitcher {
            border: none;
        }
    }

    &-Slider {
        .Slider {
            &-Wrapper {
                height: 375px;

                @include mobile {
                    height: 245px;
                }

                @include small-mobile {
                    height: 185px;
                }
            }
        }
    }

    .Image {
        &-Image {
            object-fit: cover;

            @include mobile {
                object-fit: contain;
            }
        }
    }
}
