/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.CategoryProductList {
    &-Page {
        &_layout {
            &_list {
                display: block;
                max-width: none;

                .ProductCard-Link {
                    width: 100%;
                }

                .ProductCard {
                    margin-block-end: 32px;

                    &-Name {
                        height: auto;
                        white-space: initial;
                    }

                    &-Price {
                        margin-block: 16px 24px
                    }

                    &-Picture {
                        &.Image {
                            width: 322px;
                        }
                    }

                    &-Button {
                        display: grid;
                        grid-template-columns: 1fr auto;
                        row-gap: 8px;

                        @include wide-desktop {
                            display: flex;
                            justify-content: space-between;
                        }

                        button {
                            width: 100%;
                            font-size: 16px;

                            @include desktop {
                                width: auto;
                            }
                        }
                    }

                    &-AttrInfo {
                        grid-column: 1 / 2 span;
                    }
                }
            }

            &_grid {
                grid-column-gap: 32px;
                margin-block-start: 0;

                @include desktop {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include mobile {
                    grid-template-columns: repeat(1, 1fr);
                }

                .ProductCard {
                    margin-block-end: 40px;

                    &-Name {
                        white-space: unset;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;

                        @include desktop {
                            min-height: 96px;
                        }
                    }

                    &-Price {
                        margin-block-start: 0;
                    }

                    &-PriceContainer {
                        margin-block: 24px;
                    }

                    &-Picture {
                        &.Image {
                            @include desktop {
                                max-width: 322px;
                            }
                        }
                    }

                    &-GridButton {
                        button {
                            font-size: 16px;
                            width: 252px;
                        }

                        @include desktop {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .ProductCard {
        &-Name {
            margin-block: 0;
            padding-block-end: 0;
        }

        &-Picture {
            @include desktop {
                height: 100%;
                padding-block-end: 0;
            }

            &.Image {
                @include desktop {
                    min-height: 195px;
                }

                .Image {
                    &-Image {
                        object-fit: cover;

                        @include mobile {
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        &-Info {
            display: flex;
            align-items: flex-start;
        }

        &-Price {
            min-height: auto;

            .ProductPrice {
                &-Price {
                    align-self: flex-start;
                }
            }
        }

        &-Content {
            button {
                text-transform: capitalize;
            }

            &_layout_list {
                max-width: 708px;
                width: 100%;
                margin-inline-start: 0;

                .ProductCard {
                    &-Price {
                        justify-content: flex-start;

                        @include tablet {
                            flex-direction: column;
                        }
                    }

                    &-PriceWrapper {
                        .ProductPrice {
                            &-HighPrice {
                                margin-inline-start: 20px;
                            }
                        }
                    }
                }
            }
        }

        .ProductCompareButton {
            .Button {
                width: unset;
                margin-inline-start: 8px;
            }
        }
    }
}

