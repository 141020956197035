/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductCompareButton {
    .Button {
        &:hover {
            box-shadow: none;
        }

        .CompareIcon {
            fill: var(--body-content-secondary-color);

            &_isActive {
                fill: var(--primary-base-color);
            }
        }
    }
}
