/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductCard {
    height: auto;

    &-ContentContainer {
        height: 100%;
    }

    &-Content {
        padding: 16px;
        justify-content: space-between;

        @include desktop {
            height: 100%;
        }
    }

    &-MainInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-Name {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }

    &-Brand {
        position: absolute;
        inset-block-start: -10px;
    }

    &-FigureReview {
        padding: 0;
    }

    &:hover {
        @include desktop {
            box-shadow: none;
            background: var(--background-hover-color);
        }

        .ProductCard {
            &-Content {
                @include desktop {
                    background: var(--background-hover-color);
                }
            }

            &-Badge {
                @include desktop {
                    background-color: var(--background-white-color);
                }
            }
        }
    }

    &-Picture {
        height: 0;
        padding-block-end: 78.57%;
        vertical-align: top;
    }

    &-Button {
        align-items: flex-end;
        width: 100%;
        gap: 32px;

        .Button {
            min-width: 130px;
        }
    }

    &-AdditionalInfo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-shrink: 1;
        gap: 8px;
        margin-block-end: 24px;

        &_hasNoSpacing {
            margin-block-end: 0;
        }
    }

    &-Badge {
        background-color: var(--background-hover-color);
        padding-inline: 8px;
        padding-block: 4px;
        margin-block-end: 0;
        text-align: center;
        border-radius: 1px;
        color: var(--body-content-color);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .ProductPrice {
        &-LeasingPrice {
            color: var(--primary-base-color);
            margin: 0;
        }
    }

    &_layout_list {
        .ProductPrice {
            &-LeasingPrice {
                flex: 0 0 auto;
            }
        }
    }
}
