/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductCarLocator {
    width: 100%;
    padding: 0;
    vertical-align: middle;

    @include desktop {
        line-height: 20px;
    }

    &:hover, :focus {
        --button-hover-padding: 0;
    }

    @include mobile {
        min-width: 168px;
        line-height: 20px;
    }

    &-Wrapper {
        background-color: var(--background-hover-color);
        padding: 16px;
        margin-block-start: 16px;

        @include desktop {
            padding: 32px;
            padding-block-end: 44px;
            margin-block-start: 32px;
        }
    }

    &-Heading {
        margin-block-start: 0;
        margin-block-end: 24px;

        @include desktop {
            margin-block-end: 32px;
        }
    }

    &-StoreName {
        margin-block-start: 0;
        margin-block-end: 16px;

        @include desktop {
            margin-block-end: 24px;
        }
    }

    &-StoreAttr {
        display: flex;
        align-items: flex-start;
        gap: 15px;

        &:not(:last-child) {
            margin-block-end: 8px;

            @include desktop {
                margin-block-end: 16px;
            }
        }

        p, a {
            margin-block-end: 0;
            font-size: 16px;
            flex: 1;
            font-weight: 400;

            @include desktop {
                font-size: 18px;
            }
        }
    }

    &-Footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        margin-block-start: 32px;

        @include desktop {
            justify-content: space-between;
            flex-direction: row;
        }

        p {
            margin-block-end: 0;
            font-size: 20px;
            font-weight: 700;
            text-align: center;

            @include desktop {
                font-size: 24px;
                text-align: start;
            }
        }
    }

    &-Button {
        width: 252px;
        border-color: #2F2FFF;

        @include desktop {
            width: 142px;
        }
    }

    &-Address, &-Email, &-Phone {
        width: 24px;
        height: 24px;
    }

    &-PhoneValue {
        a {
            font-weight: 450;
        }
    }

    &-Address {
        background-image: url('../../style/icons/ProductCarLocator/pin.svg');
    }

    &-Email {
        background-image: url('../../style/icons/ProductCarLocator/mail.svg');
    }

    &-Phone {
        background-image: url('../../style/icons/ProductCarLocator/phone.svg');
    }

    .Popup-Content {
        font-size: 18px;
        padding: 56px 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile {
            font-size: 16px;
        }

        &-info {
            text-align: center;
            font-weight: 400;

            strong {
                font-weight: 700;

                @include mobile {
                    display: block;
                    font-weight: 500;
                }
            }
        }

        &-Button {
            font-size: 16px;
            margin-block-start: 24px;
            max-width: 222px;
            text-align: center;
            width: 100%;

            @include desktop {
                margin-block-start: 32px;
                width: 100%;
            }
        }
    }
}
