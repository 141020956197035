/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

$button-color: #2F2FFF;

.ProductLease {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--background-hover-color);
    margin-block-start: 16px;
    padding: 16px;

    @include desktop {
        padding: 32px;
    }

    &-Heading {
        margin-block-start: 0;
    }

    &-SubHeading {
        font-weight: 500;
    }

    label {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    input, select {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .Field-RadioLabel {
        [type='radio']:checked {
            ~ span {
                color: #000;
            }
        }

        .input-control {
            display: none;
        }

        span {
            margin: 0 auto;
            font-size: 32px;
        }
    }

    &-LeaseActions {
        display: flex;
        gap: 15px;
        padding-block-end: 40px;
        margin-block-end: 20px;
        border-bottom: 1px solid #BBB;

        > * {
            width: 100%;
            max-width: 142px;
        }

        @include mobile {
            > * {
                max-width: 252px;
                min-width: auto;
            }
        }
    }

    .ProductCarLocator-Button {
        @include mobile {
            width: 100%;
        }
    }

    &-MonthlyPayment {
        h3 {
            font-weight: 500;
            margin-block-start: 40px;
            margin-block-end: 25px;
        }
    }

    #result {
        font-size: 18px;
        margin-block-start: 10px;
        text-align: center;
    }

    &-SelectPeriod {
        display: flex;
        gap: 12px;

        .Field_type_radio {
            background-color: #D9D9D9;
            margin-block-start: 0;
            border: 1px solid #D9D9D9;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                border: 1px solid #000;

                span {
                    color: #000;
                }
            }

            &:has([type='radio']:checked) {
                background-color: #fff;
                border: 1px solid #000;
            }
        }

        span {
            color: #BBBBBB;
        }
    }

    &-Notice {
        > div {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            font-weight: 500;
            margin-block-end: 15px;

            &::before {
                content: '';
                width: 24px;
                height: 24px;
                background: url("../../style/icons/LeasingPage/checkmark-icon.svg") no-repeat;
                background-size: contain;
            }

            &:last-child {
                margin-block-end: 0;

                &::before {
                    background: url("../../style/icons/ProductPage/icon_info.svg") no-repeat;
                    background-size: contain;
                }
            }
        }

        .message {
            font-weight: normal;
            width: unset;
            min-width: 250px;
        }
    }
}

.es-forms-floating-button {
    display: none;
}

.hidden {
    display: none;
}
