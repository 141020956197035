/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductAttributes {
    &-AttributeBlock {
        border: 1px solid var(--background-stroke-color);
        padding: 32px;
        margin-block-start: 32px;

        @include small-mobile {
            padding: 16px;
        }
    }

    &-Group {
        padding: 0 0 32px 0;

        @include small-mobile {
            padding-block-end: 24px;
        }
    }

    &-Attributes {
        grid-template-columns: 1fr auto;
        justify-content: unset;
        column-gap: 30px;
        row-gap: 16px;

        @include mobile {
            grid-template-columns: 1fr 1fr;
        }

        @include small-mobile {
            grid-template-columns: auto auto;
            column-gap: 15px;
        }
    }

    &-AttributeLabel,
    &-ValueLabel {
        border-block-end: none;
        font-size: 18px;
        padding: 0;

        @include mobile {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &-AttributeLabel {
        overflow: visible;
    }

    &-ValueLabel {
        word-wrap: break-word;

        @include small-mobile {
            text-align: end;
        }
    }
}
