/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

/* stylelint-disable declaration-no-important */

.ProductPage {
    margin-inline: 0;
    margin-block-start: 0;

    &-Wrapper {
        grid-column-gap: 52px;
        padding-block-start: 0;
        padding-block-end: 32px;
    }

    &-ActionsWrapper {
        padding-inline: 16px;

        @include desktop {
            padding-inline: 0;
        }
    }

    &-Features {
        padding: 16px;
        border: 1px solid var(--background-stroke-color);
        margin-block-end: 4.8rem;

        @include desktop {
            padding: 32px;
            margin-block-end: 15.2rem;
        }

        &-Heading {
            font-size: 24px;
            margin-block-start: 0;
            margin-block-end: 24px;

            @include desktop {
                font-size: 32px;
                margin-block-end: 32px;
            }
        }

        &-Grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 24px;
            column-gap: 115px;

            @include desktop {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .Feature {
        &-Detail {
            display: flex;
            flex-direction: column;
            gap: 24px;

            &-Heading {
                margin-block-start: 0;
                margin-block-end: 16px;
                font-size: 18px;
            }

            &-Grid {
                display: grid;
                gap: 8px;
                column-gap: 50px;
                grid-template-columns: 1fr;

                @include desktop {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            &-List {
                display: flex;
                flex-direction: column;
                gap: 8px;

                &-Item {
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-block-end: 0;
                    margin-inline-start: 18px;
                    font-size: 18px;

                    &::before {
                        padding-inline-end: 0;
                        position: relative;
                        inset-inline-start: 0;
                        inset-block-start: 0;
                        content: '';
                    }
                }
            }
        }
    }
}
/* Styling 'Save as PDF' for PDP */
@media print {
    @page {
        margin-block: 25mm;
        margin-inline: 25mm;
    }

    .Header {
        position: relative;

        &-Wrapper {
            margin-block-end: 0;
        }

        &_isHidden {
            transform: translateY(0);
        }

        &-CompareButtonWrapper,
        .StoreSwitcher,
        .Menu-MenuWrapper {
            display: none;
        }
    }

    .ProductGallery-Slider {
        padding-block-end: 0 !important;
    }

    .ProductAdditionalInfo {
        margin-block-start: 8px;
        margin-block-end: 8px;
        border: none;
        font-size: 14pt !important;

        &-PDF {
            display: none;
        }
    }

    .ProductActions {
        font-size: 14pt !important;

        &-Wrapper {
            margin-block-end: 8px;
        }

        &-Title {
            margin-block-start: 8px;
            margin-block-end: 0 !important;
            font-size: 18pt !important;
        }

        &-WrapperTitle {
            display: none;
        }
    }

    .ProductCarLocator {
        &-Heading {
            font-size: 14pt !important;
            margin-block-end: 16px;
        }

        &-Wrapper {
            margin-block-start: 0;
            padding-block-start: 8px;
        }

        &-StoreName {
            margin-block-end: 0;
            font-size: 14pt !important;
        }

        &-Footer {
            display: none;
        }

        p {
            font-size: 14pt !important;
        }

        &-Address {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('../../style/icons/ProductCarLocator/pin.svg');
        }

        &-Email {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('../../style/icons/ProductCarLocator/mail.svg');
        }

        &-Phone {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('../../style/icons/ProductCarLocator/phone.svg');
        }
    }

    .ProductAttributes {
        &-AttributeBlock {
            margin-block-start: 0 !important;
            padding-block-start: 72px !important;
            display: block;
            position: relative;
            overflow: visible !important;
            break-inside: auto !important;
            border: none !important;
        }

        &-Group {
            font-size: 18pt !important;
        }

        &-Attributes {
            font-size: 14pt !important;
        }
    }

    .ProductPage-Features {
        display: block;
        position: relative;
        break-before: page;
        border: none;
    }

    .amasty-label-image,
    .Breadcrumbs,
    .ProductActions-AddToCartWrapper,
    .ProductLease,
    .ProductLinks,
    .StickyFooter-Wrapper,
    .Footer,
    .NavigationTabs-Nav,
    .Slider-Crumbs {
        display: none;
    }
}
/* stylelint-enable declaration-no-important */
