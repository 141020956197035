/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductListWidget {
    @include desktop {
        padding-inline: 52px;
    }

    @include tablet {
        padding-inline: 32px;
    }

    .ProductCard {
        &-GridButton {
            display: none;
        }

        &-ProductCompareButton {
            display: none;
        }
    }
}
