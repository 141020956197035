/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductAttributeValue {
    &-Text {
        span {
            font-size: 16px;
            display: block;

            .ProductAttributeValue-SubLabel {
                font-size: 16px;
                font-weight: 400;
            }
        }

        @include desktop {
            padding-block-end: 14px;
        }
    }
}
