/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --price-color: var(--body-content-color);
    --price-discount-color: var(--body-content-secondary-color);
    --price-vat-percentage-color: #666666;
    --price-old-color: #0000FF;
}

.ProductPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 0 0;
    min-height: 40px;

    &-PriceValue {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-block-start: 0;
    }

    &-VatPercentage {
        margin-inline-start: .5rem;
        font-size: 1.6rem;
        color: var(--price-vat-percentage-color);
        font-weight: 400;
    }

    ins {
        .ProductPrice {
            &-PriceValue {
                color: var(--price-color);
            }
        }
    }

    &-HighPrice {
        color: var(--price-discount-color);
        order: 2;
    }

    del {
        margin-inline-end: 0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--price-old-color);
    }

    &-TierPrice {
        margin-inline-start: 4px;
        margin-block-end: 0;
    }
}
