/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ProductLinks {
    margin-block-end: 80px;

    @include desktop {
        margin-block-end: 104px;
    }

    &-Title {
        text-align: center;
        padding-inline: 16px;
        padding-block: 32px;

        @include desktop {
            padding-block: 56px;
        }
    }

    &-List {
        grid-gap: 32px;
        grid-template-columns: repeat(4, 1fr);
    }

    .ProductCard {
        &-GridButton {
            display: none;
        }

        &-ProductCompareButton {
            display: none;
        }
    }
}
